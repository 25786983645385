/* eslint-disable */

<template>
  <div class="">
    <div class="vx-row">
      <div class="vx-col w-1/4">
        <div class="main-border border-2 rounded-lg overflow-hidden vx-card mb-6">
          <div
            :class="[processListComplete === false ? 'bg-danger-gradient' : '', processListComplete === true ? 'bg-success-gradient' : '']"
            class="flex items-center justify-between p-5 bg-primary-gradient"
          >
            <div class="flex">
              <feather-icon icon="GridIcon" svgClasses="w-8 h-8 mr-1 self-center" />
              <h3 class="font-bold self-center ml-1">Process List</h3>
            </div>
            <div class="flex">
              <feather-icon v-if="processListComplete === true" icon="CheckCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
              <feather-icon v-if="processListComplete === false" icon="XCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
            </div>
          </div>
          <div class="p-3">
            <div v-for="item in processList" :key="item.name" class="p-3 my-2">
              <div class="flex justify-between items-center">
                <div class="flex justify-between items-center">
                  <vs-switch v-model="item.value" class="mr-2" color="success" />
                  <h3 class="ml-2 font-bold break-all">{{ item.name }}</h3>
                </div>
                <feather-icon
                  v-if="item.marked !== null && item.answer === true && item.marked === item.answer"
                  icon="CheckCircleIcon"
                  svgClasses="w-8 h-8 ml-4 text-success self-center"
                />
                <feather-icon
                  v-if="item.marked !== null && item.answer === false && item.marked === item.answer"
                  icon="XCircleIcon"
                  svgClasses="w-8 h-8 ml-4 text-danger self-center"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mb-4 vx-card">
          <div class="border-1 rounded-lg p-5" :class="tunnelActive ? 'gradient-border' : 'main-border'">
            <div class="flex justify-between items-center mb-4">
              <h3 class="font-bold">{{ tunnelActive ? 'Active' : 'Inactive' }} - Site A</h3>
              <feather-icon
                @click="tunnelActive = !tunnelActive"
                :icon="tunnelActive ? 'ShieldIcon' : 'ShieldOffIcon'"
                svgClasses="h-6 w-6 hover:text-primary cursor-pointer"
              />
            </div>
            <div class="flex justify-center items-center p-3">
              <div
                class="main-border rounded-lg p-3 cursor-pointer z-10"
                @click="selectPCPopup = !selectPCPopup"
                style="background: #040815"
              >
                <div class="flex flex-col justify-center items-center z-10">
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/computer-health-monitoring.png"
                    width="120"
                    alt=""
                    class="mb-2"
                  />
                  <h3 class="font-bold">Technician PC</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col w-3/4">
        <div class="vx-card">
          <div class="border-1 rounded-lg p-5" :class="tunnelActive ? 'gradient-border' : 'main-border'">
            <div class="flex justify-center items-center mb-12">
              <div class="main-border rounded-lg bg-light flex items-center justify-center p-3 z-10">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/pfsense-logo.png"
                  width="50"
                  alt=""
                  class="mr-2 ml-0"
                />
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/openvpn-icon.png"
                  width="50"
                  alt=""
                  class="mr-2 ml-2"
                />
                <h3 class="font-bold mr-2 ml-2">Site A Gateway w/VPN</h3>
              </div>
              <div class="line-down-site" />
            </div>
            <div class="vx-row mb-12">
              <div class="vx-col w-1/3">
                <div class="flex justify-center items-center">
                  <div class="line-down-router" />
                </div>
              </div>
              <div class="vx-col w-1/3">
                <div class="flex justify-center items-center">
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/switch-hub.png"
                    class="z-10"
                    width="85"
                    alt=""
                  />
                  <div class="line-across-router" />
                </div>
              </div>
              <div class="vx-col w-1/3">
                <div class="flex justify-center items-center">
                  <div class="line-down-router" />
                </div>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-1/3 z-10">
                <div class="rounded-lg p-3 z-10 mb-1" style="background: #181d2a">
                  <div class="flex items-center justify-between">
                    <h5 class="mb-2">Node 01</h5>
                    <!-- <vs-switch v-model="nodeSwitches.nodeOne"/> -->
                  </div>
                  <div class="flex items-center">
                    <img
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/windows-icon.png"
                      width="50"
                      alt=""
                      class="mr-3"
                    />
                    <div>
                      <p><b>OS:</b> Windows Server 2012 R2</p>
                      <p><b>Uptime:</b> 10d 17h 5m</p>
                      <p><b>IP:</b> 10.60.0.30</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vx-col w-1/3 flex justify-center items-center">
                <div class="line-across-nodes" />
              </div>
              <div class="vx-col w-1/3 z-10">
                <div class="rounded-lg p-3 z-10" style="background: #181d2a">
                  <div class="flex items-center justify-between">
                    <h5 class="mb-2">Node 04</h5>
                    <!-- <vs-switch v-model="nodeSwitches.nodeFour"/> -->
                  </div>
                  <div class="flex items-center">
                    <img
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/ubuntu-server.png"
                      width="50"
                      alt=""
                      class="mr-3"
                    />
                    <div>
                      <p><b>OS:</b> Ubuntu 18.04 LTS</p>
                      <p><b>Uptime:</b> 186d 18h 28m</p>
                      <p><b>IP:</b> 10.60.0.32</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-1/3 z-10 flex items-center">
                <div class="rounded-lg p-3 w-full" style="background: #181d2a">
                  <div class="flex items-center justify-between">
                    <h5 class="mb-2">Node 02</h5>
                    <!-- <vs-switch v-model="nodeSwitches.nodeTwo"/> -->
                  </div>
                  <div class="flex items-center">
                    <img
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/debian-logo.png"
                      width="50"
                      alt=""
                      class="mr-3"
                    />
                    <div>
                      <p><b>OS:</b> Debian 10 (buster)</p>
                      <p><b>Uptime:</b> 91d 13h 42m</p>
                      <p><b>IP:</b> 10.60.0.30</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vx-col w-1/3 flex justify-center items-center">
                <lottie-animation
                  style="z-index: 1; position: relative"
                  :width="150"
                  path="https://connect-cdn.intellectualpoint.com/assets/lotties/power-plug.json"
                  :loop="true"
                  :autoPlay="true"
                  :speed="0.5"
                />
                <div class="line-across-nodes" />
                <div class="line-down-nodes" />
              </div>
              <div class="vx-col w-1/3 z-10 flex items-center">
                <div class="rounded-lg p-3 w-full" style="background: #181d2a">
                  <div class="flex items-center justify-between">
                    <h5 class="mb-2">Node 05</h5>
                    <!--  <vs-switch v-model="nodeSwitches.nodeFive"/> -->
                  </div>
                  <div class="flex items-center">
                    <img
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/windows-icon.png"
                      width="50"
                      alt=""
                      class="mr-3"
                    />
                    <div>
                      <p><b>OS:</b> Windows Server 2019</p>
                      <p><b>Uptime:</b> 11d 1h 17m</p>
                      <p><b>IP:</b> 10.60.0.12</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-1/3 z-10">
                <div class="rounded-lg p-3" style="background: #181d2a">
                  <div class="flex items-center justify-between">
                    <h5 class="mb-2">Node 03</h5>
                    <!-- <vs-switch v-model="nodeSwitches.nodeThree"/> -->
                  </div>
                  <div class="flex items-center">
                    <img
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/rhel-server-icon.png"
                      width="50"
                      alt=""
                      class="mr-3"
                    />
                    <div>
                      <p><b>OS:</b> RHEL 8.4</p>
                      <p><b>Uptime:</b> 35d 1h 5m</p>
                      <p><b>IP:</b> 10.60.0.31</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vx-col w-1/3 flex justify-center items-center">
                <div class="line-across-nodes" />
              </div>
              <div class="vx-col w-1/3 z-10">
                <div class="rounded-lg p-3" style="background: #181d2a">
                  <div class="flex items-center justify-between">
                    <h5 class="mb-2">Node 06</h5>
                    <!-- <vs-switch v-model="nodeSwitches.nodeSix"/> -->
                  </div>
                  <div class="flex items-center">
                    <img
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/windows-home-icon.png"
                      width="50"
                      alt=""
                      class="mr-3"
                    />
                    <div>
                      <p><b>OS:</b> Windows 10 Pro</p>
                      <p><b>Uptime:</b> 0d 8h 26m</p>
                      <p><b>IP:</b> 10.60.0.10</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <movable-popup
        refVal="movableNodeOneRef"
        title="Node 1 Terminal - Click Here to Drag This Popup"
        :active="nodeOneTerminal"
        v-on:close="nodeOneTerminal = false"
        padding="0px"
      >
        <template v-slot:content>
          <vue-command
            id="terminal_node_1"
            :commands="commandsNodeOne"
            :prompt="'C:\\Users\\WindowsServer2012>'"
            class="rounded"
            intro="Microsoft Windows [Version 10.0.18363.1256] (c) 2012 Microsoft Corporation. All rights reserved."
            :show-intro="true"
            style="background-color: #111111"
            title="Command Prompt"
          />
        </template>
      </movable-popup>

      <movable-popup
        refVal="movableNodeFourRef"
        title="Node 4 Terminal - Click Here to Drag This Popup"
        :active="nodeFourTerminal"
        v-on:close="nodeFourTerminal = false"
        padding="0px"
      >
        <template v-slot:content>
          <vue-command
            id="terminal_node_4"
            :commands="commandsNodeFour"
            :prompt="'root@ubuntu:~#'"
            class="rounded"
            intro="Welcome to Ubuntu 22.04 LTS (GNU/Linux 5.15.0-25-generic x86_64)"
            :show-intro="true"
            style="background-color: #111111"
            title="Terminal"
          />
        </template>
      </movable-popup>

      <vs-popup :active.sync="selectPCPopup" title="💻 Select Terminal to Attach">
        <div class="p-2">
          <div class="grid grid-cols-2 gap-4" v-if="tunnelActive">
            <vx-card
              class="cursor-pointer"
              @click="
                selectPCPopup = false;
                nodeOneTerminal = true;
              "
            >
              <div class="flex justify-center items-center">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/windows-icon.png"
                  class="h-8 w-8 mr-1"
                />
                <h1 class="font-light ml-1">Node 1</h1>
              </div>
            </vx-card>
            <vx-card
              class="cursor-pointer"
              @click="
                selectPCPopup = false;
                nodeFourTerminal = true;
              "
            >
              <div class="flex justify-center items-center">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/368736922169769984/ubuntu-server.png"
                  class="h-8 w-8 mr-1"
                />
                <h1 class="font-light ml-1">Node 4</h1>
              </div>
            </vx-card>
          </div>
          <h2 class="font-light" v-else>No Devices Found...</h2>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';
import LottieAnimation from '../../../../../../../../../../src/components/lottie/LottieAnimation.vue';
import VueCommand, { createStdout } from 'vue-command';
import 'vue-command/dist/vue-command.css';
import MovablePopup from '../../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      tunnelActive: false,

      selectPCPopup: false,
      nodeOneTerminal: false,
      nodeFourTerminal: false,

      commandsNodeOne: {
        mema: () => createStdout('Is the best developer in the world'),
        help: () => createStdout('Available commands:<br>&nbsp;tasklist<br>&nbsp;netstat [args]'),
        tasklist: () =>
          createStdout(
            'Image&nbsp;Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PID&nbsp;Session&nbsp;Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Session#&nbsp;&nbsp;&nbsp;&nbsp;Mem&nbsp;Usage<br>\n' +
              '=========================&nbsp;========&nbsp;================&nbsp;===========&nbsp;============<br>\n' +
              'System&nbsp;Idle&nbsp;Process&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4&nbsp;K<br>\n' +
              'System&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;264&nbsp;K<br>\n' +
              'smss.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;288&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1,084&nbsp;K<br>\n' +
              'wininit.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;464&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4,008&nbsp;K<br>\n' +
              'winlogon.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;508&nbsp;Console&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5,880&nbsp;K<br>\n' +
              'services.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;552&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9,840&nbsp;K<br>\n' +
              'lsass.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;560&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;106,816&nbsp;K<br>\n' +
              'svchost.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;728&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11,056&nbsp;K<br>\n' +
              'LogonUI.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;852&nbsp;Console&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;26,236&nbsp;K<br>\n' +
              'svchost.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1076&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;14,876&nbsp;K<br>\n' +
              'spoolsv.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1376&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13,800&nbsp;K<br>\n' +
              'Microsoft.ActiveDirectory&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1400&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;38,728&nbsp;K<br>\n' +
              'svchost.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1436&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8,312&nbsp;K<br>\n' +
              'dns.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1580&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;343,956&nbsp;K<br>\n' +
              'svchost.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1420&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8,972&nbsp;K<br>\n' +
              'mspaint.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3704&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;943,956&nbsp;K<br>\n' +
              'sqlwriter.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;312&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6,276&nbsp;K<br>\n' +
              'sqlservr.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3540&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;37,160&nbsp;K<br>\n' +
              'svchost.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2928&nbsp;Services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13,772&nbsp;K<br>\n' +
              'winlogon.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;308&nbsp;RDP-Tcp#1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5,900&nbsp;K<br>\n' +
              'ServerManagerLauncher.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4296&nbsp;RDP-Tcp#1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2,824&nbsp;K<br>\n' +
              'tasklist.exe&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5112&nbsp;RDP-Tcp#1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6,124&nbsp;K<br><br>\n',
          ),
        netstat(x) {
          const keys = Object.keys(x);

          if (keys[1] === 'o' && keys[2] === 'a' && keys[3] === 'n') {
            return createStdout(
              'Active&nbsp;Connections<br>\n' +
                '<br>\n' +
                '&nbsp;&nbsp;Proto&nbsp;&nbsp;Local&nbsp;Address&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Foreign&nbsp;Address&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;State&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PID<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:88&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;560<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:135&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;772<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:389&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;560<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:443&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:445&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:47001&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:49152&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;464<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:49179&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2044<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;10.60.0.30:139&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;10.60.0.30:389&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.10.10.22:39936&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ESTABLISHED&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;560<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;10.60.0.30:389&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.10.10.25:53366&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ESTABLISHED&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;560<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;10.60.0.30:443&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;73.31.128.18:63043&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ESTABLISHED&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3704<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;10.60.0.30:56539&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.10.10.11:49157&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ESTABLISHED&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;560<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;10.60.0.30:56540&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.10.10.13:49155&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ESTABLISHED&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;560<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;10.60.0.30:53&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1580<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;10.60.0.30:139&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4<br>\n' +
                '&nbsp;&nbsp;TCP&nbsp;&nbsp;&nbsp;&nbsp;127.0.0.1:53&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTENING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1580<br><br>',
            );
          }

          return createStdout('<span class="text-danger">Invalid Arguments</span>');
        },
      },
      commandsNodeFour: {
        mema: () => createStdout('Is the best developer in the world'),
        help: () => createStdout('Available commands:<br>&nbsp;top<br>&nbsp;netstat [args]'),
        top: () =>
          createStdout(
            'top&nbsp;-&nbsp;19:38:29&nbsp;up&nbsp;26&nbsp;min,&nbsp;&nbsp;1&nbsp;user,&nbsp;&nbsp;load&nbsp;average:&nbsp;0.09,&nbsp;0.04,&nbsp;0.11<br>\n' +
              'Tasks:&nbsp;129&nbsp;total,&nbsp;&nbsp;&nbsp;1&nbsp;running,&nbsp;128&nbsp;sleeping,&nbsp;&nbsp;&nbsp;0&nbsp;stopped,&nbsp;&nbsp;&nbsp;0&nbsp;zombie<br>\n' +
              '%Cpu(s):&nbsp;&nbsp;0.0&nbsp;us,&nbsp;&nbsp;0.3&nbsp;sy,&nbsp;&nbsp;0.0&nbsp;ni,&nbsp;99.3&nbsp;id,&nbsp;&nbsp;0.3&nbsp;wa,&nbsp;&nbsp;0.0&nbsp;hi,&nbsp;&nbsp;0.0&nbsp;si,&nbsp;&nbsp;0.0&nbsp;st<br>\n' +
              'MiB&nbsp;Mem&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;468.0&nbsp;total,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;50.2&nbsp;free,&nbsp;&nbsp;&nbsp;&nbsp;129.2&nbsp;used,&nbsp;&nbsp;&nbsp;&nbsp;288.6&nbsp;buff/cache<br>\n' +
              'MiB&nbsp;Swap:&nbsp;&nbsp;&nbsp;1000.0&nbsp;total,&nbsp;&nbsp;&nbsp;&nbsp;999.5&nbsp;free,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.5&nbsp;used.&nbsp;&nbsp;&nbsp;&nbsp;306.6&nbsp;avail&nbsp;Mem<br>\n' +
              '<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;PID&nbsp;USER&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PR&nbsp;&nbsp;NI&nbsp;&nbsp;&nbsp;&nbsp;VIRT&nbsp;&nbsp;&nbsp;&nbsp;RES&nbsp;&nbsp;&nbsp;&nbsp;SHR&nbsp;S&nbsp;&nbsp;%CPU&nbsp;&nbsp;%MEM&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TIME+&nbsp;COMMAND<br>\n' +
              '&nbsp;&nbsp;47420&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;17232&nbsp;&nbsp;10632&nbsp;&nbsp;&nbsp;8228&nbsp;S&nbsp;&nbsp;&nbsp;0.3&nbsp;&nbsp;&nbsp;2.2&nbsp;&nbsp;&nbsp;0:00.15&nbsp;sshd<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;167224&nbsp;&nbsp;10936&nbsp;&nbsp;&nbsp;6416&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;2.3&nbsp;&nbsp;&nbsp;0:10.18&nbsp;systemd<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.01&nbsp;kthreadd<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;-20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;I&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;rcu_gp<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;-20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;I&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;rcu_par_gp<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;-20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;I&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;kworker/0:0H-events_highpri<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;I&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:01.43&nbsp;kworker/0:1-cgwb_release<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;-20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;I&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;mm_percpu_wq<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;rcu_tasks_rude_<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;rcu_tasks_trace<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:01.16&nbsp;ksoftirqd/0<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;I&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:01.13&nbsp;rcu_sched<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;14&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rt&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.01&nbsp;migration/0<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;918&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rt&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.01&nbsp;leafpad/0<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;15&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-51&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;idle_inject/0<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;16&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;cpuhp/0<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;17&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;kdevtmpfs<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;-20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;I&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;netns<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;19&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;-20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;I&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;inet_frag_wq<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;kauditd<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;21&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;khungtaskd<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;22&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;S&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;oom_reaper<br>\n' +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;23&nbsp;root&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;-20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;I&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0.0&nbsp;&nbsp;&nbsp;0:00.00&nbsp;writeback<br>\n',
          ),
        netstat(x) {
          const keys = Object.keys(x);

          if (keys[1] === 'o' && keys[2] === 'a' && keys[3] === 'n') {
            return createStdout(
              'Active&nbsp;Internet&nbsp;connections&nbsp;(servers&nbsp;and&nbsp;established)<br>\n' +
                'Proto&nbsp;Recv-Q&nbsp;Send-Q&nbsp;Local&nbsp;Address&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Foreign&nbsp;Address&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;State&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PID<br>\n' +
                'tcp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;0.0.0.0:22&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTEN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;181<br>\n' +
                'tcp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;127.0.0.51:53&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0.0.0.0:*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LISTEN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4144<br>\n' +
                'tcp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;10.60.0.32:38340&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;185.125.190.38:80&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TIME_WAIT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;910<br>\n' +
                'tcp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;10.60.0.32:443&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;71.125.190.38:443&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ESTABLISHED&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;918<br>\n' +
                'tcp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;10.60.0.32:51306&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;91.189.91.38:80&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TIME_WAIT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;710<br>\n' +
                'tcp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0&nbsp;10.60.0.32:51098&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;91.189.91.121:80&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TIME_WAIT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5<br>\n',
            );
          }

          return createStdout('<span class="text-danger">Invalid Arguments</span>');
        },
      },

      processList: [
        {
          name: 'mspaint.exe',
          value: false,
          answer: true,
          marked: false,
        },
        {
          name: 'svchost.exe',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'wanlogon.exe',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'cmd.exe',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'watchdog',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'migration',
          value: false,
          answer: false,
          marked: null,
        },
        {
          name: 'leafpad',
          value: false,
          answer: true,
          marked: false,
        },
      ],
      processListComplete: null,

      nodeSwitches: {
        nodeOne: {
          value: false,
          answer: true,
          marked: false,
        },
        nodeFour: {
          value: true,
          answer: true,
          marked: false,
        },
      },
    };
  },
  methods: {
    togglePopup(popup) {
      if (this[popup] === true) {
        this[popup] = 'transition_out';
        return setTimeout(() => {
          this[popup] = false;
        }, 400);
      }
      this[popup] = true;
    },
    markSimulation() {
      let success = true;
      let totalScore = 0;

      this.processList.forEach((question, index) => {
        if (question.value === question.answer) {
          this.processList[index].marked = true;
          totalScore++;
        } else {
          success = false;
          this.processList[index].marked = false;
        }
      });
      this.processListComplete = success;
      /**
       for (const [key, value] of Object.entries(this.nodeSwitches)) {
    if (value.value === value.answer) {
      this.nodeSwitches[key].marked = true
      totalScore++
    } else {
      this.nodeSwitches[key].marked = false
    }
  } **/

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
    LottieAnimation,
    MovablePopup,
    VueCommand,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    tunnelActive(val) {
      if (this.nodeOneTerminal || this.nodeFourTerminal) {
        this.$vs.notify({
          color: 'danger',
          title: 'Connection Dropped',
          text: 'You have lost connection to the gateway you were connected to.',
          icon: 'feather',
          iconPack: 'icon-alert-circle',
          position: 'top-right',
        });
      }
      if (!val) {
        this.nodeOneTerminal = false;
        this.nodeFourTerminal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.line-down-site {
  position: absolute;
  width: 4px;
  height: 130px;
  margin-top: 100px;
  background-color: #ffffff;
}

.line-across-router {
  position: absolute;
  width: 66%;
  height: 4px;
  background-color: #ffffff;
}

.line-down-router {
  position: absolute;
  width: 4px;
  height: 150px;
  margin-top: 200px;
  background-color: #ffffff;
}

.line-across-nodes {
  position: absolute;
  width: 70%;
  height: 4px;
  border-bottom: 7px dotted #0053ff;
}

.line-down-nodes {
  position: absolute;
  width: 1px;
  height: 45%;
  border-right: 7px dotted #2d73ff;
}

.gradient-border {
  padding: 1.5rem !important;
  position: relative;
}

.gradient-border::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  padding: 2px; /* control the border thickness */
  background: linear-gradient(var(--angle), #32f520, #0053ff, #1475cf);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: 3s rotate linear infinite;
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

.vs-switch {
  background: #181d2a;
}
</style>
